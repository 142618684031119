import React from 'react'
import { graphql, PageProps } from 'gatsby'
// Types
import { PageInfoProps, PublicationProps } from '../types/pages'
import { SectionBase } from '../types/sections'
// Components
import { Layout, Seo } from '../components/commons'
import Section from '../components/sections'

interface DataQuery {
  datoCmsProduct: {
    pageInfo: PageInfoProps
    locale: string
    meta: PublicationProps
    sections: SectionBase[]
  }
}

const Product: React.FC<PageProps<DataQuery>> = ({ data }) => {
  const { datoCmsProduct: productData } = data
  return (
    <Layout headerDark={productData.pageInfo.headerDark}>
      <Seo
        slug={productData.pageInfo.slug}
        publication={productData.meta}
        lang={productData.locale}
        content={productData.pageInfo.seo}
      />
      {productData.sections.map(
        (s: SectionBase) => s?.model && <Section key={s.id} sectionApiKey={s.model.apiKey} data={s} />
      )}
    </Layout>
  )
}

export const query = graphql`
  query ProductQuery($id: String!) {
    datoCmsProduct(id: { eq: $id }) {
      locale
      meta {
        firstPublishedAt
        publishedAt
        updatedAt
      }
      pageInfo {
        ...pageInfoFragment
      }
      sections {
        ...sectionHeroSimpleFragment
        ...sectionRoundedHeroFragment
        ...sectionFeatureSimpleFragment
        ...sectionFeaturesStepFragment
        ...sectionLogoWallFragment
        ...sectionTitleOnlyFragment
        ...sectionTabulatedFragment
        ...sectionBlogFragment
        ...sectionVideoFragment
        ...sectionPodcastFragment
        ...sectionFeaturesListFragment
        ...sectionTestimonialFragment
      }
    }
  }
`

export default Product
